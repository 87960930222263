<template>
  <v-container>
    <v-row class="about-body primary--text text-center">
      <v-col cols="12">
        <h2>About Page</h2>
        <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {},
};
</script>
<style scoped>
.about-body {
  margin: 0;
}
</style>
